<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
      <div class="header-label col-lg-9 col-12">Janta Darbar Participants</div>
      <div class="
              col-lg-3 col-12
              text-end
              d-flex
              justify-content-end
              custom-flex-cloumn-mob
            ">
        <div class="service-plan-box-outer">
          <div class="d-flex justify-content-between align-items-center">
            <div class="call-label">Participants Count : </div>
            <div class="call-count ps-1">
              {{ totalRecords ? totalRecords : "0" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-3 col-12">
        <div class="ticket-history-box-outer mb-3">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
              <div class="customer-label-group mb-2">
                <label class="form-label">Scheduled for Date & Time</label>
                <div class="from-label-value">
                  {{ format_timestamp(campaigndetails.dq4) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-md-9 col-12">
        <div class="card-template">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-12 custom-body-logs-scroll">
              <div class="custom-form-group mb-0 d-flex" >
                <div>
                    
                <div><label class="form-label mb-0 fw-bold me-2">Address: </label>
                    {{ campaigndetails.dq5 ? campaigndetails.dq5 : "N/A" }}
                </div>
                <Divider v-if="campaigndetails.dq6" />
                <label v-if="campaigndetails.dq6" class="form-label fw-bold me-2">Google Map Link: </label>
                    <a v-if="campaigndetails.dq6" style="word-break: break-all;" :href="campaigndetails.dq6" target="_blank">{{ campaigndetails.dq6 }}</a>
                </div>
                <div v-if="campaigndetails.dq7" class="ms-3">
                  <img class="img-fluid " :src="'https://storage.googleapis.com/' + this.storageimgpath + '/jantadarbar/' + campaigndetails.dq7" width="150px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-ultima-datatable mt-2" style="height: calc(100vh - 267px)">
      <DataTable :value="campaignLogList" :scrollable="true" scrollHeight="flex" :paginator="true" :rows="30" :lazy="true"
        :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        :loading="loading" dataKey="dr1">
        <template v-if="!loading" #empty>No records found.</template>
        <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
        <Column field="tokan" header="Token No." headerStyle="width: 9%" bodyStyle="width: 9%">
          <template #body="{ data }">
            <div>
              <div class="text-capitalize">
                {{ data.dr1 ? data.dr1 : "N/A" }}
              </div>
            </div>
          </template>
        </Column>
        <Column field="query" header="Date & Time" headerStyle="width:12%" bodyStyle="width:12%">
          <template #body="{ data }">
            <div>
              {{ format_gmttime(data.dr5) }}
            </div>
          </template>
        </Column>
        <Column field="name" header="Voter Name" headerStyle="width:20%" bodyStyle="width:20%">
          <template #body="{ data }">
            <div class="text-capitalize">
              {{ data.dr3 ? data.dr3 : "N/A" }}
            </div>
          </template>
        </Column>
        <Column field="query" header="Response Message" headerStyle="width:25%" bodyStyle="width:25%">
          <template #body="{ data }">
            <div>
                {{ data.dr6 ? data.dr6 : "N/A" }}
            </div>
          </template>
        </Column>
        <Column field="participation" header="Participation" headerStyle="width:10%" bodyStyle="width:10%">
          <template #body="{ data }">
            <div>
               <span v-if="data.dr7 == 0" class="status-permanently-suspended">Cancelled</span>
               <span v-else-if="data.dr7 == 1" class="status-active">Yes</span>
               <span v-else>N/A</span>
            </div>
          </template>
        </Column>
        <Column field="cancelmessage" header="Cancel Message" headerStyle="width:24%" bodyStyle="width:24%">
          <template #body="{ data }">
            <div class="w-100">
              <div>{{ data.dr8 ? data.dr8 : "N/A" }}</div>
              <divider v-if="data.dr9" />
              <div v-if="data.dr9" class="small">{{ format_gmttime(data.dr9) }}</div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </template>
  <script>
  import ApiService from "../../service/ApiService";
  import { useRoute } from "vue-router";
  import moment from 'moment';
  export default {
    data() {
      return {
        campaignLogList: [],
        campaigndetails: '',
        page_no: 0,
        totalRecords: 0,
        loading: false,
        client_info: '',
        storageimgpath: '',
      };
    },
    ApiService: null,
    created() {
      this.ApiService = new ApiService();
    },
  
    mounted() {
      const route = useRoute();
      this.routeParam = route.params.eventId;
      this.loading = true;
      this.getParticipatedJantaDarbarList({ dq1: this.routeParam });
      this.client_info = JSON.parse(localStorage.client_info);
      this.storageimgpath = this.client_info.maa24;
    },
    beforeUnmount() {
      clearInterval(this.timer5);
    },
    methods: {
      getParticipatedJantaDarbarList(item) {
        this.ApiService.getParticipatedJantaDarbarList(item).then((data) => {
          if (data.success === true) {
            this.campaignLogList = data.participatedList;
            this.campaigndetails = data.data;
            this.totalRecords = data.totalCount;
            this.loading = false;
          } else {
            this.loading = false;
            this.campaignLogList = null;
            this.campaigndetails = '';
            this.totalRecords = 0;
          }
        });
      },
      changePage(event) {
        this.page_no = event.page;
        this.getParticipatedJantaDarbarList({ page_no: this.page_no, co1: this.routeParam });
      },
      toggleMessageInfoList(id, event) {
        this.$refs[id].toggle(event);
      },
      format_timestamp(value) {
        if (value) {
          var localTime = new Date(value * 1000).toUTCString();
          return moment.utc(localTime).format("DD/MM/YYYY @ hh:mm A");
        } else {
          return '-';
        }
      },
      format_gmttime(value) {
        if (value) {
          var localTime = new Date(value * 1000).toUTCString();
          return moment(localTime).format("DD/MM/YYYY @ hh:mm A");
        } else {
          return '-';
        }
      },
    },
  };
  </script>
  <style scoped>
  .box-icon-inner {
    background: #f2f4fb 0% 0% no-repeat padding-box;
    border-radius: 4px;
    width: 40px;
    height: 40px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
  }
  
  .card-template {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #c1d4f3;
    border-radius: 4px;
    padding: 14px 15px 0px 15px;
  }
  
  .overlay-reg-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 16px;
    margin-bottom: 6px;
  }
  
  .log-header-sublabel {
    font-family: "AcuminPro-SemiBold";
    font-size: 17px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
  }
  
  .service-plan-box-outer {
    background: #e4f8f0 0% 0% no-repeat padding-box;
    border: 1px solid #e0e5ed;
    border-radius: 4px;
    padding: 6px 10px;
  }
  
  .service-plan-box-outer .call-label {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
    user-select: none;
  }
  
  .service-plan-box-outer .call-count {
    font-family: "AcuminPro-SemiBold";
    font-size: 16px;
    letter-spacing: 0.32px;
    color: #4a5463;
    text-align: left;
    line-height: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .custom-body-logs-scroll {
    overflow-y: auto;
      position: relative;
      overflow-x: hidden;
      height: 92px;
      margin-bottom: 10px;
  }
  .custom-body-logs-scroll::-webkit-scrollbar {
      width: 0.3em !important;
      height: 0.3em !important;
  }
  
    .custom-body-logs-scroll::-webkit-scrollbar-thumb {
      background-color: rgba(31, 30, 30, 0.19) !important;
      border-radius: 3px !important;
  }
  .status-active {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #4daa53;
  line-height: 12px;
}
.status-permanently-suspended {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}
  </style>